// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-deyatelnost-meropriyatiya-js": () => import("./../../../src/pages/deyatelnost/meropriyatiya.js" /* webpackChunkName: "component---src-pages-deyatelnost-meropriyatiya-js" */),
  "component---src-pages-deyatelnost-proekty-js": () => import("./../../../src/pages/deyatelnost/proekty.js" /* webpackChunkName: "component---src-pages-deyatelnost-proekty-js" */),
  "component---src-pages-dokumenty-dokumenty-dlya-vstupleniya-js": () => import("./../../../src/pages/dokumenty/dokumenty-dlya-vstupleniya.js" /* webpackChunkName: "component---src-pages-dokumenty-dokumenty-dlya-vstupleniya-js" */),
  "component---src-pages-dokumenty-otchety-js": () => import("./../../../src/pages/dokumenty/otchety.js" /* webpackChunkName: "component---src-pages-dokumenty-otchety-js" */),
  "component---src-pages-dokumenty-uchreditelnye-dokumenty-js": () => import("./../../../src/pages/dokumenty/uchreditelnye-dokumenty.js" /* webpackChunkName: "component---src-pages-dokumenty-uchreditelnye-dokumenty-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-o-nas-donate-js": () => import("./../../../src/pages/o-nas/donate.js" /* webpackChunkName: "component---src-pages-o-nas-donate-js" */),
  "component---src-pages-o-nas-o-fonde-js": () => import("./../../../src/pages/o-nas/o-fonde.js" /* webpackChunkName: "component---src-pages-o-nas-o-fonde-js" */),
  "component---src-pages-o-nas-partnery-js": () => import("./../../../src/pages/o-nas/partnery.js" /* webpackChunkName: "component---src-pages-o-nas-partnery-js" */),
  "component---src-pages-o-nas-popechitelskij-sovet-js": () => import("./../../../src/pages/o-nas/popechitelskij-sovet.js" /* webpackChunkName: "component---src-pages-o-nas-popechitelskij-sovet-js" */),
  "component---src-pages-o-nas-sovet-js": () => import("./../../../src/pages/o-nas/sovet.js" /* webpackChunkName: "component---src-pages-o-nas-sovet-js" */),
  "component---src-pages-o-nas-team-js": () => import("./../../../src/pages/o-nas/team.js" /* webpackChunkName: "component---src-pages-o-nas-team-js" */),
  "component---src-pages-policy-js": () => import("./../../../src/pages/policy.js" /* webpackChunkName: "component---src-pages-policy-js" */),
  "component---src-pages-smi-o-nas-js": () => import("./../../../src/pages/smi-o-nas.js" /* webpackChunkName: "component---src-pages-smi-o-nas-js" */),
  "component---src-templates-blog-page-js": () => import("./../../../src/templates/blogPage.js" /* webpackChunkName: "component---src-templates-blog-page-js" */),
  "component---src-templates-meropriyatiya-page-js": () => import("./../../../src/templates/meropriyatiyaPage.js" /* webpackChunkName: "component---src-templates-meropriyatiya-page-js" */),
  "component---src-templates-project-page-js": () => import("./../../../src/templates/projectPage.js" /* webpackChunkName: "component---src-templates-project-page-js" */),
  "component---src-templates-team-page-js": () => import("./../../../src/templates/teamPage.js" /* webpackChunkName: "component---src-templates-team-page-js" */)
}

